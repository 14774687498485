import React, {FC} from 'react';
import Layout from '../../components/layout';
import Section from '../../components/section';
import {Translate} from '../../components/translations';

const TermsOfUse: FC = () => (
  <Layout>
    <Section pageheroPading className="policy-section" lgPd>
      <div className="container mb-5">
        <div className="breadcrumb_content text-center">
          <h1 className="f_size_40 f_700 t_color4 l_height40 text-center">Terms of Service</h1>
          <p className="f_300 page-hero-subTitle">Last modified May 30, 2022</p>
        </div>
      </div>
      <div className="col-lg-12">
        <Translate name="TERM_OF_USE_PAGE_CONTENT_HEXOSPARK" />
      </div>
    </Section>
  </Layout>
);

export default TermsOfUse;
